const config = {
  devPort: 4003,

  // localStoreInstanceId: 'd65a2706a40e4a3fae72d00fb40a4ac5',
  // localStoreInstanceId: 'f10cd538335348eba2761a2987340952',
  // localStoreInstanceId: '4f9d01b8a7b249eea759300f6f3c9f9d',
  // localStoreInstanceId: '32820fac0ee14c118797319379ed8a10',
  // localStoreInstanceId: '41769d74a9284c9e92bbdf29a32af035',
  // localStoreInstanceId: '09371951e720468d8aaf3c5f3b5cd7ab',
  localStoreInstanceId: 'cded0c4dfa6f4943b719ae0168cd3c1b', // UAT Acct with Stripe
  // localStoreInstanceId: '2f7b45802a5847aebb124496341e4358',
  // localStoreInstanceId: 'pBC6HhlybUOK+0ZwyV/ApAUDAAAAIgMAADEwMDAwMDczNjc',
  // localStoreInstanceId: '9f592dca854f4e7da5d6ef15e98d396d',
  // sets the default theme from the /brands folder
  brand: 'websitebuilder',
  // brand: 'endurance',

  // set the environment, this will change the urls 'dev', 'prototype3'
  // env: 'qa',
  env: 'uat',
  // Logger for redux, defaults to false when in production
  reduxLogger: true,

  product: 'store',

  getCurrentBrand (env) {
    if (env && env.brand) {
      return env.brand
    }

    return this.brand
  },

  getCustomAlias () {
    return {
      authentication_alias: 'core/authentication-shop'
    }
  },

  getCustomRuntimeConfig (runtimeConfig) {
    return Object.assign({}, runtimeConfig, {
      _fetchXsrfTokenHeaderName: 'X-CSRF-TOKEN',
      _fetchXsrfTokenEndpoint: '/accounts/v1.0/session/preauth',
      _fetchXsrfTokenSiteName: 'app-gateway',
      _fetchTokenSiteName: 'control-panel',
      _enableAppGateway: true,
      _defaultApiName: 'store-shop'
    })
  }
}

module.exports = config
