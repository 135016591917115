import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

export function getIsOrderOnlyPickup (products) {
  const notAPickupProduct = find(products, x => x.localPickup === false)
  return isEmpty(notAPickupProduct)
}

export function getDoesOrderContainShippingItems (products) {
  const shippingItem = find(products, x => x.lineType === 1) // order line type is shipping
  return !isEmpty(shippingItem)
}
