import * as constants from './constants'
import find from 'lodash/find'
import filter from 'lodash/filter'
import toLower from 'lodash/toLower'
import isString from 'lodash/isString'
import map from 'lodash/map'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import { setItemsInStorage, getItemsInStorage } from '../helpers/storage'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'
import AuthenticationHelper from 'authentication_alias'

const initialState = {
  items: [],

  getStoreSettingsRetrieving: true,
  getStoreSettingsResponse: {},
  getStoreSettingsError: null,

  checkDiscountCodeRetrieving: false,
  checkDiscountCodeResponse: {},
  checkDiscountCodeError: null,

  itemsWithProductInfo: [],

  checkoutWindow: null,
  discounts: []
}

/**
 * Merge the product info (with name, image, stock info) with the cart cookie array
 *
 * @param {*} state
 * @returns {array} An array of the items combined with the product information
 */
function mergeProductInfoWithCart (state) {
  return map(state.items, item => {
    const newItem = { ...item }
    const { productId } = item
    newItem.productInfo =
      state.getProductInfoFromServerResponse &&
      (find(
        state.getProductInfoFromServerResponse.products,
        productInfo => toLower(productInfo.id) === toLower(productId)
      ) ||
        {})

    if (isNil(newItem.productInfo)) {
      newItem.productInfo = {}
    } else {
      const stock = newItem.productInfo.stock

      // Check if the product exceeds the max stock amount. Stock can be null when track stock is disabled on the product
      newItem.productInfo.showMaxStockWarning = stock !== null && stock >= 0 && newItem.quantity > stock
    }

    return newItem
  })
}

function clearProductFromCart (state, productId, variantId) {
  return {
    ...state,
    items: [
      ...filter(
        state.items,
        item => !(toLower(item.productId) === toLower(productId) && toLower(item.variantId) === toLower(variantId))
      )
    ]
  }
}

function generateItemsData (state) {
  return JSON.stringify(state.items)
}

function storeItemsInStorage (state) {
  setItemsInStorage(AuthenticationHelper.storeId, generateItemsData(state))
}

function receiveItemsFromCookie (storeId) {
  const id = storeId || AuthenticationHelper.storeId
  const items = getItemsInStorage(id)
  if (isString(items)) {
    try {
      const arr = JSON.parse(items)
      if (arr) {
        return arr
      }
    } catch (ex) {
      // just ignore this for now, we only need this for a refresh
    }
  }

  return []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SESSION_FULFILLED: {
      const newState = {
        ...state,
        items: receiveItemsFromCookie(action.body.storeId)
      }

      newState.itemsWithProductInfo = mergeProductInfoWithCart(newState)

      return newState
    }

    case constants.UPDATE_SETTINGS: {
      const { isTestMode } = action.body
      return {
        ...state,
        isTestMode: isTestMode
      }
    }

    case constants.GET_INSIGHTS_IDENTITY: {
      return {
        ...state,
        insightsTrackingId: get(action.body, 'insightsId'),
        insightsUserId: get(action.body, 'userId'),
        insightsSessionId: get(action.body, 'sessionId')
      }
    }

    case constants.ADD_PRODUCT_TO_CART: {
      let { productId, variantId, quantity, propertyBag } = action.body

      // if quantity is empty, add one
      quantity = quantity || 1

      const newState = {
        ...state,
        items: [...state.items],
        checkoutWindow: null
      }

      const existingItem = find(
        newState.items,
        item => toLower(item.productId) === toLower(productId) && toLower(item.variantId) === toLower(variantId)
      )
      if (existingItem) {
        existingItem.quantity = existingItem.quantity + quantity
      } else {
        // add product if it doesn't exist
        newState.items.push({
          quantity,
          productId,
          variantId,
          propertyBag
        })
      }

      storeItemsInStorage(newState)

      newState.itemsWithProductInfo = mergeProductInfoWithCart(newState)

      return newState
    }
    case constants.UPDATE_PRODUCT_QUANTITY: {
      const { productId, variantId, quantity } = action.body

      const newState = {
        ...state,
        items: [...state.items],
        checkoutWindow: null
      }

      const existingItem = find(
        newState.items,
        item => toLower(item.productId) === toLower(productId) && toLower(item.variantId) === toLower(variantId)
      )
      if (existingItem) {
        existingItem.quantity = quantity
      }

      storeItemsInStorage(newState)

      newState.itemsWithProductInfo = mergeProductInfoWithCart(newState)

      return newState
    }

    case constants.UPDATE_FULFILLMENT_METHOD: {
      const { productId, variantId, methodId } = action.body
      const newState = {
        ...state,
        items: [...state.items]
      }
      const itemToUpdate = find(newState.items, item => toLower(item.productId) === toLower(productId) && toLower(item.variantId) === toLower(variantId))
      itemToUpdate.fulfillmentMethodSelected = methodId

      storeItemsInStorage(newState)

      newState.itemsWithProductInfo = mergeProductInfoWithCart(newState)

      return newState
    }

    case constants.REMOVE_PRODUCT_FROM_CART: {
      let { productId, variantId, quantity } = action.body

      // if quantity is empty, add one
      quantity = quantity || 1

      let newState = {
        ...state,
        items: [...state.items],
        checkoutWindow: null
      }

      const existingItem = find(
        newState.items,
        item => toLower(item.productId) === toLower(productId) && toLower(item.variantId) === toLower(variantId)
      )
      if (existingItem) {
        existingItem.quantity = existingItem.quantity - quantity
      }

      // remove product from state
      if (existingItem.quantity <= 0) {
        newState = clearProductFromCart(newState, productId, variantId)
      }

      storeItemsInStorage(newState)

      newState.itemsWithProductInfo = mergeProductInfoWithCart(newState)

      return newState
    }
    case constants.CLEAR_PRODUCT_FROM_CART: {
      const { productId, variantId } = action.body

      const newState = clearProductFromCart(state, productId, variantId)

      storeItemsInStorage(newState)

      newState.itemsWithProductInfo = mergeProductInfoWithCart(newState)
      newState.checkoutWindow = null

      return newState
    }
    case constants.RESET_CHECK_OUT_SESSION: {
      return {
        ...state,
        createCheckOutSessionResponse: undefined
      }
    }
    case constants.CLEAR_DISCOUNT_CODE: {
      return {
        ...state,
        checkDiscountCodeResponse: {},
        checkoutWindow: null
      }
    }
    case constants.GET_PRODUCT_INFO_FROM_SERVER_PENDING: {
      return {
        ...state,
        getProductInfoFromServerRetrieving: true,
        getProductInfoFromServerResponse: null,
        getProductInfoFromServerError: null,
        getProductInfoFromServerExtraArgs: action.extraArguments
      }
    }
    case constants.GET_PRODUCT_INFO_FROM_SERVER_ERROR: {
      return {
        ...state,
        getProductInfoFromServerRetrieving: false,
        getProductInfoFromServerError: action.body
      }
    }
    case constants.GET_PRODUCT_INFO_FROM_SERVER_FULFILLED: {
      const newState = {
        ...state,
        getProductInfoFromServerRetrieving: false,
        getProductInfoFromServerResponse: action.body
      }

      newState.itemsWithProductInfo = mergeProductInfoWithCart(newState)

      return newState
    }
    case constants.CLEAR_SHOPPING_CART: {
      const newState = {
        ...state,
        items: [],
        itemsWithProductInfo: [],
        getProductInfoFromServerResponse: null,
        getProductInfoFromServerExtraArgs: '',
        checkoutWindow: null
      }

      storeItemsInStorage(newState)

      return newState
    }
    case constants.SET_CHECKOUT_WINDOW: {
      const newState = {
        ...state,
        checkoutWindow: action.body
      }

      return newState
    }
    case constants.SET_DISCOUNTS: {
      return {
        ...state,
        discounts: action.body
      }
    }
    case constants.CLEAR_DISCOUNTS: {
      return {
        ...state,
        discounts: []
      }
    }
  }

  return (
    ReducerHelper.listenToFetchActions(state, action, constants.CREATE_CHECK_OUT_SESSION) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_STORE_SETTINGS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.CHECK_DISCOUNT_CODE) ||
    state
  )
}

export default reducer
