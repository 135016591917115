import * as constants from './constants'
import { fetchAsJson } from '@eig-builder/core-utils/helpers/fetch-helper'
import { api } from '@eig-builder/core-utils/helpers/url-helper'
import isEmpty from 'lodash/isEmpty'
import each from 'lodash/each'
import get from 'lodash/get'
import { matchPath } from 'react-router'
import { withInstanceId } from '../../../modules/cart/store/utils'

/**
 * Check if a user is signed in on the server
 *
 * @returns Promise
 */
const getSession = () => {
  let params

  // use the store id and checkout hash if provided
  // todo for later refactor less hard coded
  const routes = ['/checkout/:storeId?/:hash?', '/order/:storeId/:hash']

  let storeId
  let hash

  each(routes, function (route) {
    const match = matchPath(window.location.pathname, {
      path: route,
      exact: false,
      strict: true
    })

    if (get(match, 'params.storeId') && get(match, 'params.storeId')) {
      storeId = get(match, 'params.storeId')
      hash = get(match, 'params.hash')
    }
  })

  if (!isEmpty(storeId) && !isEmpty(hash)) {
    params = {
      storeId,
      hash
    }
  }

  return fetchAsJson(
    withInstanceId(api('/v1.0/session', 'store-shop', params)),
    {
      key: constants.GET_SESSION,
      convertToCamelCase: true
    })
}

export { getSession }
