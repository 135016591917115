const CheckoutSteps = {
  BUYER_INFORMATION: 'BuyerInformation',
  BILLING_INFORMATION: 'BillingInformation',
  SHIPPING_INFORMATION: 'ShippingInformation',
  PAYMENT_INFORMATION: 'PaymentInformation',
  CONFIRM: 'Confirm',
  PURCHASE: 'Purchase'
}

export default CheckoutSteps
