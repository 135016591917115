import * as constants from './constants'
import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

const initialState = {
  items: [],

  getDownloadInfoRetrieving: true,
  getDownloadInfoResponse: {},
  getDownloadInfoError: null
}

const reducer = (state = initialState, action) => {
  return ReducerHelper.listenToFetchActions(state, action, constants.GET_DOWNLOAD_INFO) ||
  ReducerHelper.listenToFetchActions(state, action, constants.GET_DOWNLOAD_SIGNEDURL) ||
  state
}

export default reducer
