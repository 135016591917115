import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

const Routes = [
  {
    pattern: '/download/:storeId/:downloadHash',
    component: LazyLoadWithCircularProgress(() => import('./containers/base')),
    isPublic: true
  }
]

export default Routes
