export const getStorageCartItemsKey = id => {
  const STORAGENAMEPREFIX = 'store_shopping_cart_'
  return `${STORAGENAMEPREFIX}${id}`
}

export const setItemsInStorage = (storeId, data) => {
  const keyName = getStorageCartItemsKey(storeId)
  window && window.localStorage && window.localStorage.setItem(keyName, data)
}

export const getItemsInStorage = storeId => {
  const keyName = getStorageCartItemsKey(storeId)
  return window && window.localStorage && window.localStorage.getItem(keyName)
}

export const clearItemsInStorage = storeId => {
  const keyName = getStorageCartItemsKey(storeId)
  window && window.localStorage && window.localStorage.removeItem(keyName)
}
