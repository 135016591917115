/* eslint-disable no-case-declarations */
import * as constants from './constants'

import CheckoutSteps from '../enums/checkout-steps'
import CheckoutProductMixTypes from '../enums/checkout-product-mix-types'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'
import groupBy from 'lodash/groupBy'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { getIsOrderOnlyPickup } from '../helpers/checkout-helper'

const DIGITAL_PRODUCT_LINE_TYPE = 5

const CHECKOUT_STEP_ORDER_PRIORITY = [
  CheckoutSteps.BUYER_INFORMATION,
  CheckoutSteps.BILLING_INFORMATION,
  CheckoutSteps.SHIPPING_INFORMATION,
  // CheckoutSteps.PURCHASE
  // CheckoutSteps.PAYMENT_INFORMATION,
  CheckoutSteps.CONFIRM
]

const findNextStepOrConfirm = (availableStepsByOrder, currentStep) => {
  const currentIndex = availableStepsByOrder.indexOf(currentStep)
  const nextPotentialIndex = currentIndex + 1
  if (availableStepsByOrder.length === nextPotentialIndex) {
    return false
  } else {
    return availableStepsByOrder[nextPotentialIndex]
  }
}

const findHigherStep = (highestFocusedStep, newFocusedStep) => {
  if (highestFocusedStep === null) {
    return newFocusedStep
  }
  return CHECKOUT_STEP_ORDER_PRIORITY[highestFocusedStep] > CHECKOUT_STEP_ORDER_PRIORITY[newFocusedStep]
    ? highestFocusedStep
    : newFocusedStep
}

const findOrderLineProductTypeMix = (products) => {
  let digitalProductCount = 0
  products.map((product) => {
    if (product.lineType === DIGITAL_PRODUCT_LINE_TYPE) {
      digitalProductCount++
    }
  })
  let productMixType = CheckoutProductMixTypes.NO_DIGITAL
  if (products.length === digitalProductCount) {
    productMixType = CheckoutProductMixTypes.ONLY_DIGITAL
  } else if (digitalProductCount) {
    productMixType = CheckoutProductMixTypes.MIX_OF_DIGITAL
  }
  return productMixType
}

const setupStateForForm = (formName) => {
  const state = {}
  state[`get${formName}FormRetrieving`] = false
  state[`get${formName}FormResponse`] = {}
  state[`submit${formName}FormRetrieving`] = false
  state[`submit${formName}FormResponse`] = {}
  state[`submit${formName}FormExtraArgs`] = {}
  return state
}

const initialState = {

  productMixType: null,

  currentlyFocusedStep: null,
  highestFocusedStep: null,
  availableStepsByOrder: [],

  trackingByStep: {},

  paymentMarks: null,

  legalTexts: null,

  singlePaymentMethod: false,

  paymentProviderSelected: false,
  paymentProviderData: {},

  shippingOptionSelected: false,
  shippingOptionData: {},

  buyerAddressData: {},

  getCustomThankYouRetrieving: false,
  getCustomThankYouResponse: {},

  isCheckoutOrderConfirmed: false,

  checkoutOrderConfirmedPaymentProvider: null,

  getCheckoutInfoRetrieving: false,
  getCheckoutInfoResponse: {},

  getCompletedOrderInfoRetrieving: false,
  getCompletedOrderInfoResponse: {},

  ...setupStateForForm(CheckoutSteps.BUYER_INFORMATION),
  ...setupStateForForm(CheckoutSteps.SHIPPING_INFORMATION),
  // ...setupStateForForm(CheckoutSteps.PAYMENT_INFORMATION),
  ...setupStateForForm(CheckoutSteps.BILLING_INFORMATION),
  // ...setupStateForForm(CheckoutSteps.CONFIRM),
  ...setupStateForForm(CheckoutSteps.PURCHASE)

}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SUBMIT_BUYER_INFORMATION_FORM_FULFILLED: {
      const nextStep = findNextStepOrConfirm(state.availableStepsByOrder, CheckoutSteps.BUYER_INFORMATION)
      const highestFocusedStep = findHigherStep(state.highestFocusedStep, nextStep)
      const newState = {
        ...state,
        highestFocusedStep,
        currentlyFocusedStep: nextStep,
        submitBuyerInformationFormSuccess: true,
        submitBuyerInformationFormPending: false
      }
      return newState
    }
    case constants.SUBMIT_BUYER_INFORMATION_FORM_PENDING: {
    // Backend takes one naming convention for address POST, and returns another for GET
      const buyerAddressData = {
        fullName: `${action.extraArguments.first_name} ${action.extraArguments.last_name}`,
        address: action.extraArguments.street_name,
        city: action.extraArguments.city,
        state: action.extraArguments.state,
        postalCode: action.extraArguments.postal_code,
        email: action.extraArguments.email_address,
        phone: action.extraArguments.phone,
        country: action.extraArguments.country
      }
      const newState = {
        ...state,
        buyerAddressData
      }
      return newState
    }
    case constants.SUBMIT_BILLING_INFORMATION_FORM_FULFILLED: {
      const nextStep = findNextStepOrConfirm(state.availableStepsByOrder, CheckoutSteps.BILLING_INFORMATION)
      const highestFocusedStep = findHigherStep(state.highestFocusedStep, nextStep)
      const newState = {
        ...state,
        highestFocusedStep,
        currentlyFocusedStep: nextStep,
        submitBillingInformationFormSuccess: true,
        submitBillingInformationFormPending: false
      }
      return newState
    }
    case constants.SUBMIT_SHIPPING_INFORMATION_FORM_FULFILLED: {
      const nextStep = action.extraArguments.shouldAdvanceStep
        ? findNextStepOrConfirm(state.availableStepsByOrder, CheckoutSteps.SHIPPING_INFORMATION)
        : CheckoutSteps.SHIPPING_INFORMATION
      const highestFocusedStep = findHigherStep(state.highestFocusedStep, nextStep)
      const newState = {
        ...state,
        highestFocusedStep,
        currentlyFocusedStep: nextStep,
        submitShippingInformationFormSuccess: true,
        submitShippingInformationFormPending: false
      }
      return newState
    }
    case constants.TRIGGER_STEP_ADVANCEMENT: {
      const nextStep = findNextStepOrConfirm(state.availableStepsByOrder, CheckoutSteps.SHIPPING_INFORMATION)
      const highestFocusedStep = findHigherStep(state.highestFocusedStep, nextStep)
      const newState = {
        ...state,
        highestFocusedStep,
        currentlyFocusedStep: nextStep
      }
      return newState
    }
    case constants.USER_REQUESTED_MANUAL_STEP_MOVE: {
      const highestFocusedStepPriority = CHECKOUT_STEP_ORDER_PRIORITY.indexOf(state.highestFocusedStep)
      const requestedStepPriority = CHECKOUT_STEP_ORDER_PRIORITY.indexOf(action.body.currentStep)
      const newState = {
        ...state,
        currentlyFocusedStep: highestFocusedStepPriority > requestedStepPriority
          ? action.body.currentStep
          : state.currentlyFocusedStep
      }
      return newState
    }

    case constants.GET_CHECKOUT_INFO_FULFILLED: {
      const availableSteps = action.body.availableSteps.map(step => { return upperFirst(camelCase(step)) })

      const availableStepsByOrder = CHECKOUT_STEP_ORDER_PRIORITY.filter(stepInOrder => { return availableSteps.indexOf(stepInOrder) !== -1 })
      // availableStepsByOrder.push('Purchase')

      const currentStep = upperFirst(camelCase(action.body.currentStep))

      const paymentMethods = action.body.paymentMethods
      let paymentMarks = []

      // Checkout backend APIs are responding with the payment step enabled when all providers are disable
      const indexOfPaymentInformation = availableStepsByOrder.indexOf(CheckoutSteps.PAYMENT_INFORMATION)
      if (paymentMethods === null || (paymentMethods.length === 0 && indexOfPaymentInformation !== -1)) {
        availableStepsByOrder.splice(indexOfPaymentInformation, 1)
      } else {
        paymentMarks = paymentMethods.reduce((acc, current) => {
          return [...acc, ...current.icons.filter((icon) => acc.indexOf(icon) === -1 ? icon : null)]
        }, [])
      }

      // Checkout backend API returns default shipping type #1
      const indexOfShippingInformation = availableStepsByOrder.indexOf(CheckoutSteps.SHIPPING_INFORMATION)
      let shipOptionsGroupedByProvider = {}
      if (action.body.shippingType <= 1 && indexOfShippingInformation !== -1) {
        availableStepsByOrder.splice(indexOfShippingInformation, 1)
      }
      if (action.body.shippingOptions && action.body.shippingOptions.rates !== null) {
        shipOptionsGroupedByProvider = groupBy(action.body.shippingOptions.rates, (opt) => {
          return opt.provider
        })
      }

      const productMixType = findOrderLineProductTypeMix(action.body.products)

      const newState = {
        ...state,

        productMixType,
        availableSteps,
        availableStepsByOrder,
        paymentMarks,
        highestFocusedStep: currentStep,
        currentlyFocusedStep: currentStep,

        getCheckoutInfoResponse: { ...action.body, isOrderOnlyPickup: getIsOrderOnlyPickup(action.body.products) },
        getCheckoutInfoRetrieving: false,
        getCheckoutInfoError: null,

        trackingByStep: action.body.trackers,

        legalTexts: action.body.legalTexts,

        buyerAddressData: action.body.buyerAddress,

        getShippingInformationFormResponse: {
          id: action.body.shippingId,
          type: action.body.shippingType,
          address: action.body.shippingAddress,
          options: action.body.shippingOptions,
          optionsByProvider: shipOptionsGroupedByProvider
        },

        getPaymentInformationFormRetrieving: false,
        getPaymentInformationFormResponse: {
          paymentMethods,
          billingAddress: action.body.billingAddress,
          stripePubKey: action.body.stripePublishableKey,
          stripeAccount: action.body.stripeAccount
        }
      }
      return newState
    }
    case constants.GET_BUYER_INFORMATION_FORM_FULFILLED: {
      const newState = {
        ...state,
        getBuyerInformationFormResponse: {
          ...action.body,
          productMixType: state.productMixType
        },
        getBuyerInformationFormRetrieving: false,
        getBuyerInformationFormError: false
      }
      return newState
    }
    case constants.GET_BILLING_INFORMATION_FORM_FULFILLED: {
      const newState = {
        ...state,
        getBillingInformationFormResponse: {
          ...action.body,
          buyerAddressData: state.buyerAddressData
        },
        getBillingInformationFormRetrieving: false,
        getBillingInformationFormError: false
      }
      return newState
    }
    case constants.GET_SHIPPING_INFORMATION_FORM_FULFILLED: {
      let shipOptionsGroupedByProvider = {}
      const availableStepsByOrder = CHECKOUT_STEP_ORDER_PRIORITY.filter(stepInOrder => { return state.availableSteps.indexOf(stepInOrder) !== -1 })
      const indexOfShippingInformation = availableStepsByOrder.indexOf(CheckoutSteps.SHIPPING_INFORMATION)
      if (action.body.shippingType <= 1 && indexOfShippingInformation !== -1) {
        availableStepsByOrder.splice(indexOfShippingInformation, 1)
      }
      if (action.body.shippingOptions && action.body.shippingOptions.rates !== null) {
        shipOptionsGroupedByProvider = groupBy(action.body.shippingOptions.rates, (opt) => {
          return opt.provider
        })
      }
      const newState = {
        ...state,
        getShippingInformationFormResponse: {
          id: action.body.shippingId,
          type: action.body.shippingType,
          address: action.body.shippingAddress,
          options: action.body.shippingOptions,
          optionsByProvider: shipOptionsGroupedByProvider
        },
        getShippingInformationFormRetrieving: false,
        getShippingInformationFormError: false
      }
      return newState
    }
    case constants.SUBMIT_STRIPE_ERROR:
    case constants.SUBMIT_POST_PAYMENT_INFORMATION_FORM_ERROR:
    case constants.SUBMIT_PAYMENT_INFORMATION_FORM_ERROR:
      return {
        ...state,
        submitPaymentInformationFormError: action.body
      }
    case constants.SUBMIT_POST_PAYMENT_INFORMATION_FORM_FULFILLED:
    case constants.SUBMIT_PAYMENT_INFORMATION_FORM:
      // const nextStep = findNextStepOrConfirm(state.availableStepsByOrder, CheckoutSteps.PAYMENT_INFORMATION)
      // const highestFocusedStep = findHigherStep(state.highestFocusedStep, nextStep)
      const newState = {
        ...state,
        getConfirmFormResponse: { ...state.getConfirmFormResponse },
        paymentProviderSelected: true
      }
      if (action.type === constants.SUBMIT_POST_PAYMENT_INFORMATION_FORM_FULFILLED) {
        newState.paymentProviderData = action.extraArguments
      } else {
        newState.paymentProviderData = action.body.data
      }

      // copy it to getConfirmresponse
      newState.getConfirmFormResponse.paymentProviderData = { ...action.body.data }
      newState.getConfirmFormResponse.paymentProviderSelected = true
      return newState
    case constants.GET_CONFIRM_FORM_FULFILLED: {
      const { body = {} } = action || {}
      const { shippingOptions = {}, shippingId } = body
      const { rates = [] } = shippingOptions
      const { legalTexts = {}, buyerAddressData, productMixType, paymentProviderSelected, paymentProviderData } = state || {}

      return {
        ...state,
        getConfirmFormRetrieving: false,
        getConfirmFormError: false,
        getConfirmFormResponse: {
          ...body,
          shippingOptionSelected: shippingId ? rates.find(rate => rate.id === shippingId) : false,
          requireAgreeToTermsBeforeConfirm: Object.keys(legalTexts).some(link => {
            return legalTexts[link] && true
          }),
          buyerAddressData,
          productMixType,
          paymentProviderSelected,
          paymentProviderData
        }
      }
    }
    case constants.RESET_FORM: {
      const { body = {} } = action || {}
      const { keyName } = body
      const newState = {
        ...state
      }
      newState[keyName] = null
      return newState
    }

    case constants.GET_PURCHASE_FORM_FULFILLED: {
      const { body = {} } = action || {}
      const { shippingOptions = {}, shippingId } = body
      const { rates = [] } = shippingOptions
      const { legalTexts = {}, buyerAddressData, productMixType, paymentProviderSelected, paymentProviderData } = state || {}

      return {
        ...state,
        getPurchaseFormRetrieving: false,
        getPurchaseFormError: false,
        getPurchaseFormResponse: {
          ...body,
          shippingOptionSelected: shippingId ? rates.find(rate => rate.id === shippingId) : false,
          requireAgreeToTermsBeforeConfirm: Object.keys(legalTexts).some(link => link && true),
          buyerAddressData,
          productMixType,
          paymentProviderSelected,
          paymentProviderData
        }
      }
    }

    case constants.SUBMIT_CONFIRM_FORM_FULFILLED: {
      // const nextStep = findNextStepOrConfirm(state.availableStepsByOrder, CheckoutSteps.CONFIRM)
      // const highestFocusedStep = findHigherStep(state.highestFocusedStep, nextStep)
      const newState = {
        ...state,
        // highestFocusedStep,
        // currentlyFocusedStep: nextStep,
        isCheckoutOrderConfirmed: action.body.succeeded,
        checkoutOrderConfirmedPaymentProvider: action.body,
        submitConfirmFormSuccess: true,
        submitConfirmFormPending: false
      }
      return newState
    }
    case constants.RESET_STEP_PROGRESSION: {
      const newState = {
        ...state,
        currentlyFocusedStep: CHECKOUT_STEP_ORDER_PRIORITY[0]
      }
      return newState
    }

    case constants.UPDATE_CHECKOUT_TOTALS_FULFILLED: {
    // Checkout backend API returns default shipping type #1
      const availableStepsByOrder = state.availableStepsByOrder
      const indexOfShippingInformation = availableStepsByOrder.indexOf(CheckoutSteps.SHIPPING_INFORMATION)
      if (action.body.shippingType <= 1 && indexOfShippingInformation !== -1) {
        availableStepsByOrder.splice(indexOfShippingInformation, 1)
      }

      let shipOptionsGroupedByProvider = {}
      if (action.body.shippingOptions && action.body.shippingOptions.rates !== null) {
        shipOptionsGroupedByProvider = groupBy(action.body.shippingOptions.rates, (opt) => { return opt.provider })
      }

      const newState = {
        ...state,
        availableStepsByOrder,
        getCheckoutInfoResponse: { ...action.body, isOrderOnlyPickup: getIsOrderOnlyPickup(action.body.products) },
        getCheckoutInfoRetrieving: false,
        getCheckoutInfoError: null,
        getShippingInformationFormResponse: {
          id: action.body.shippingId,
          type: action.body.shippingType,
          address: action.body.shippingAddress,
          options: action.body.shippingOptions,
          optionsByProvider: shipOptionsGroupedByProvider
        }
      }
      return newState
    }
    case constants.GET_COMPLETED_ORDER_INFO_FULFILLED: {
      const newState = {
        ...state,
        getCompletedOrderInfoRetrieving: false,
        getCompletedOrderInfoError: null,
        getCompletedOrderInfoResponse: {
          ...action.body,
          productMixType: findOrderLineProductTypeMix(action.body.products)
        }
      }
      return newState
    }
    case constants.GET_CUSTOM_THANK_YOU_FULFILLED: {
      const newState = {
        ...state,
        getCustomThankYouRetrieving: false,
        getCustomThankYouError: null,
        getCustomThankYouResponse: { ...action.body }
      }
      return newState
    }
    case constants.GET_STORE_TYPE_INFO: {
      const newState = {
        ...state,
        getStoreTypeInfoResponse: { ...action.body }
      }
      return newState
    }
  }
  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CHECKOUT_INFO) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CHECKOUT_LINKS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_BUYER_INFORMATION_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_BUYER_INFORMATION_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_SHIPPING_INFORMATION_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_BILLING_INFORMATION_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_BILLING_INFORMATION_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CONFIRM_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PAYMENT_INFORMATION_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_COMPLETED_ORDER_INFO) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CUSTOM_THANK_YOU) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CHECKOUT_TRACKING_FOR_STEP) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_POST_PAYMENT_INFORMATION_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_STORE_TYPE_INFO) ||
    state
  )
}

export default reducer
