import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CircularProgress from '@eig-builder/core-ui/CircularProgress'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import AuthenticationHelper from '../authentication-helper'

import * as actions from '../store/actions'

import './lang'
import Text from 'localize'

const mapStateToProps = (state) => {
  if (get(state.auth.getSessionResponse, 'storeId')) {
    AuthenticationHelper.isAuthenticated = true
    AuthenticationHelper.storeId = get(state.auth.getSessionResponse, 'storeId')
  }

  return {
    retrieving: state.auth.getSessionRetrieving,
    insightId: get(state.auth.getSessionResponse, 'insightId')
  }
}

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

/**
 * Authentication Container, making sure that a user is logged in, it will return the user to the redirect urls provided by the server then the user is not signed in
 *
 * @class AuthContainer
 * @extends {Component}
 * @param actions - object with all available actions
 * @param loginUrl - return login url if a user is not signed in
 * @param isAuthenticated - users signed in
 * @param children - this nodes will be displayed when a user is signed in
 */
class AuthContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    analytics: PropTypes.object,
    retrieving: PropTypes.bool,
    insightId: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.props.actions.getSession()
  }

  componentDidUpdate () {
    if (!isEmpty(this.props.insightId) && !isEmpty(this.props.analytics)) {
      this.props.analytics._callAnalyticsLoggers('setInsightId', [this.props.insightId])
    }
  }

  render () {
    if (this.props.retrieving) {
      return <div className='align-middle' style={{ textAlign: 'center', marginTop: '20%' }}><CircularProgress show centered /></div>
    }

    if (!this.props.retrieving) {
      return this.props.children
    }

    return <div><Text message='authentication.containers.index.error' /></div>
  }
}

const auth = connect(mapStateToProps, mapDispatch)(AuthContainer)

export default auth
