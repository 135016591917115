export const CLEAR_PRODUCT_FROM_CART = 'CLEAR_PRODUCT_FROM_CART'
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_FULFILLMENT_METHOD = 'UPDATE_FULFILLMENT_METHOD'
export const GET_PRODUCT_INFO_FROM_SERVER = 'GET_PRODUCT_INFO_FROM_SERVER'
export const GET_PRODUCT_INFO_FROM_SERVER_FULFILLED = 'GET_PRODUCT_INFO_FROM_SERVER_FULFILLED'
export const GET_PRODUCT_INFO_FROM_SERVER_PENDING = 'GET_PRODUCT_INFO_FROM_SERVER_PENDING'
export const GET_PRODUCT_INFO_FROM_SERVER_ERROR = 'GET_PRODUCT_INFO_FROM_SERVER_ERROR'

export const CREATE_CHECK_OUT_SESSION = 'CREATE_CHECK_OUT_SESSION'
export const RESET_CHECK_OUT_SESSION = 'RESET_CHECK_OUT_SESSION'

export const GET_STORE_SETTINGS = 'GET_STORE_SETTINGS'
export const GET_INSIGHTS_IDENTITY = 'GET_INSIGHTS_IDENTITY'

export const CHECK_DISCOUNT_CODE = 'CHECK_DISCOUNT_CODE'
export const CLEAR_DISCOUNT_CODE = 'CLEAR_DISCOUNT_CODE'

export const GET_SESSION_FULFILLED = 'GET_SESSION_FULFILLED'

export const CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART'

export const SET_CHECKOUT_WINDOW = 'SET_CHECKOUT_WINDOW'

export const SET_DISCOUNTS = 'SET_DISCOUNTS'
export const CLEAR_DISCOUNTS = 'CLEAR_DISCOUNTS'
