/* eslint-disable no-unused-expressions */
// babel Polyfill
import 'core-js/shim'
import 'regenerator-runtime/runtime'

// Add runtime config to singular runtime-config-helper
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'

/* eslint-disable import/first */
import { init as initDatadog } from '@eig-builder/core-utils/helpers/apm/datadog-helper'
// eslint-disable-next-line no-unused-vars
import { setGlobalModifyHeader } from '@eig-builder/core-utils/helpers/fetch-helper'
// eslint-disable-next-line no-unused-vars
import * as UrlHelper from '@eig-builder/core-utils/helpers/url-helper'
const { getQueryParametersFromUrl, api, addQueryParamtersToUrl, setGlobalInstanceJwt } = UrlHelper
import get from 'lodash/get'

import { render } from 'react-dom'
import React from 'react'
import Root from 'app'
import fetch from '@eig-builder/core-utils/fetch'

import './sass/main.scss'
/* eslint-enable import/first */

if (!window.EventSource) {
  import(/* webpackChunkName: "event-source-polyfill" */ 'event-source-polyfill')
}

if (!window.Intl) {
  import(/* webpackChunkName: "intl" */ 'intl')
  import(/* webpackChunkName: "intl-en" */ 'intl/locale-data/jsonp/en.js')
}

initDatadog()

// Set instanceJwt if its avaible in the url
const params = getQueryParametersFromUrl(window.location.href, true)
const instanceJwt = get(params, 'instancejwt')
const instanceId = get(params, 'instanceid')

if (process.env.NODE_ENV !== 'production' && !instanceJwt && !instanceId && getRuntimeConfig()._localStoreInstanceId) {
  render(
    <h1>wait a sec, setting up debug env...</h1>,
    document.getElementById('root')
  )

  const url = api(`/v1.0/local_development?appMarketInstanceId=${encodeURIComponent(getRuntimeConfig()._localStoreInstanceId)}&brand=${getRuntimeConfig()._brandDisplayName}`)
  fetch(url)
    .then((response) => response.json())
    .then((response) => {
      window.location.href = addQueryParamtersToUrl(window.location.href, {
        instancejwt: response.app_market_instance_jwt
      })
    })
} else {
  setGlobalModifyHeader((header) => {
    if (!instanceId && instanceJwt) {
      header['x-app-market-jwt'] = instanceJwt
    }
  })

  setGlobalInstanceJwt(instanceJwt)

  UrlHelper.globalInstanceId = () => {
    return instanceId
  }

  render(
    <Root />,
    document.getElementById('root')
  )

  if (module.hot) {
    module.hot.accept('app', () => {
      const NewRoot = require('app').default

      render(
        <NewRoot />,
        document.getElementById('root')
      )
    })
  }
}
