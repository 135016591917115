import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

const Routes = [{
  pattern: '/cart',
  component: LazyLoadWithCircularProgress(() => import('./containers/index')),
  pageTitle: 'Your cart',
  exactly: true,
  isPublic: true
}]

export default Routes
