import * as constants from './constants'
import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

const initialState = {
  isAuthenticated: false,
  getSessionRetrieving: true
}

const reducer = (state = initialState, action) => {
  return (ReducerHelper.listenToFetchActions(state, action, constants.GET_SESSION) || state)
}

export default reducer
