import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

const Routes = [{
  pattern: '/checkout/:storeId?/:hash?',
  component: LazyLoadWithCircularProgress(() => import('./containers/checkout')),
  pageTitle: 'Checkout',
  exactly: true,
  isPublic: true
}, {
  pattern: '/order/:storeId/:hash',
  component: LazyLoadWithCircularProgress(() => import('./containers/checkout-complete')),
  pageTitle: 'Order',
  exactly: true,
  isPublic: true
}]

export default Routes
