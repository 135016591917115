export const GET_CUSTOM_THANK_YOU = 'GET_CUSTOM_THANK_YOU'
export const GET_CUSTOM_THANK_YOU_FULFILLED = 'GET_CUSTOM_THANK_YOU_FULFILLED'

export const GET_CHECKOUT_INFO = 'GET_CHECKOUT_INFO'
export const GET_CHECKOUT_INFO_FULFILLED = 'GET_CHECKOUT_INFO_FULFILLED'

export const GET_BUYER_INFORMATION_FORM = 'GET_BUYER_INFORMATION_FORM'
export const GET_BUYER_INFORMATION_FORM_FULFILLED = 'GET_BUYER_INFORMATION_FORM_FULFILLED'

export const SUBMIT_BUYER_INFORMATION_FORM = 'SUBMIT_BUYER_INFORMATION_FORM'
export const SUBMIT_BUYER_INFORMATION_FORM_FULFILLED = 'SUBMIT_BUYER_INFORMATION_FORM_FULFILLED'
export const SUBMIT_BUYER_INFORMATION_FORM_PENDING = 'SUBMIT_BUYER_INFORMATION_FORM_PENDING'
export const SUBMIT_BUYER_INFORMATION_FORM_ERROR = 'SUBMIT_BUYER_INFORMATION_FORM_ERROR'

export const GET_BILLING_INFORMATION_FORM = 'GET_BILLING_INFORMATION_FORM'
export const GET_BILLING_INFORMATION_FORM_FULFILLED = 'GET_BILLING_INFORMATION_FORM_FULFILLED'

export const SUBMIT_BILLING_INFORMATION_FORM = 'SUBMIT_BILLING_INFORMATION_FORM'
export const SUBMIT_BILLING_INFORMATION_FORM_FULFILLED = 'SUBMIT_BILLING_INFORMATION_FORM_FULFILLED'

export const USER_REQUESTED_MANUAL_STEP_MOVE = 'USER_REQUESTED_MANUAL_STEP_MOVE'

export const GET_SHIPPING_INFORMATION_FORM = 'GET_SHIPPING_INFORMATION_FORM'
export const GET_SHIPPING_INFORMATION_FORM_FULFILLED = 'GET_SHIPPING_INFORMATION_FORM_FULFILLED'

export const SUBMIT_SHIPPING_INFORMATION_FORM = 'SUBMIT_SHIPPING_INFORMATION_FORM'
export const SUBMIT_SHIPPING_INFORMATION_FORM_FULFILLED = 'SUBMIT_SHIPPING_INFORMATION_FORM_FULFILLED'

export const SUBMIT_PAYMENT_INFORMATION_FORM = 'SUBMIT_PAYMENT_INFORMATION_FORM'
export const SUBMIT_PAYMENT_INFORMATION_FORM_FULFILLED = 'SUBMIT_PAYMENT_INFORMATION_FORM_FULFILLED'
export const SUBMIT_PAYMENT_INFORMATION_FORM_ERROR = 'SUBMIT_PAYMENT_INFORMATION_FORM_ERROR'
export const SUBMIT_POST_PAYMENT_INFORMATION_FORM = 'SUBMIT_POST_PAYMENT_INFORMATION_FORM'
export const SUBMIT_POST_PAYMENT_INFORMATION_FORM_ERROR = 'SUBMIT_POST_PAYMENT_INFORMATION_FORM_ERROR'
export const SUBMIT_POST_PAYMENT_INFORMATION_FORM_FULFILLED = 'SUBMIT_POST_PAYMENT_INFORMATION_FORM_FULFILLED'

export const GET_CONFIRM_FORM = 'GET_CONFIRM_FORM'
export const GET_CONFIRM_FORM_FULFILLED = 'GET_CONFIRM_FORM_FULFILLED'

export const GET_PURCHASE_FORM = 'GET_PURCHASE_FORM'
export const GET_PURCHASE_FORM_FULFILLED = 'GET_PURCHASE_FORM_FULFILLED'

export const GET_PAYMENT_INFORMATION_FORM = 'GET_PAYMENT_INFORMATION_FORM'
export const GET_PAYMENT_INFORMATION_FORM_RESPONSE = 'GET_PAYMENT_INFORMATION_FORM_RESPONSE'

export const SUBMIT_CONFIRM_FORM = 'SUBMIT_CONFIRM_INFORMATION_FORM'
export const SUBMIT_CONFIRM_FORM_FULFILLED = 'SUBMIT_CONFIRM_INFORMATION_FORM_FULFILLED'

export const GET_CHECKOUT_LINKS = 'GET_CHECKOUT_LINKS'

export const RESET_STEP_PROGRESSION = 'RESET_STEP_PROGRESSION'

export const GET_COMPLETED_ORDER_INFO = 'GET_COMPLETED_ORDER_INFO'
export const GET_COMPLETED_ORDER_INFO_FULFILLED = 'GET_COMPLETED_ORDER_INFO_FULFILLED'

export const UPDATE_CHECKOUT_TOTALS = 'UPDATE_CHECKOUT_TOTALS'
export const UPDATE_CHECKOUT_TOTALS_FULFILLED = 'UPDATE_CHECKOUT_TOTALS_FULFILLED'

export const GET_CHECKOUT_TRACKING_FOR_STEP = 'GET_CHECKOUT_TRACKING_FOR_STEP'
export const GET_CHECKOUT_TRACKING_FOR_STEP_FULFILLED = 'GET_CHECKOUT_TRACKING_FOR_STEP_FULFILLED'

export const TRIGGER_STEP_ADVANCEMENT = 'TRIGGER_STEP_ADVANCEMENT'
export const SUBMIT_STRIPE = 'SUBMIT_STRIPE'
export const SUBMIT_STRIPE_ERROR = 'SUBMIT_STRIPE_ERROR'

export const GET_STORE_TYPE_INFO = 'GET_STORE_TYPE_INFO'

export const RESET_FORM = 'RESET_FORM'
