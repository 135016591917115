import * as UrlHelper from '@eig-builder/core-utils/helpers/url-helper'

export const withInstanceId = (url) => {
  return (UrlHelper.globalInstanceId())
    ? UrlHelper.addQueryParamtersToUrl(url, { instanceId: UrlHelper.globalInstanceId() })
    : url
}

export const isGuid = (term) => {
  // regex from https://github.com/validatorjs/validator.js/blob/master/src/lib/isUUID.js
  const myRx = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i
  const result = myRx.exec(term)
  return !!result
}

export const isString = (term) => {
  return typeof term === 'string'
}
