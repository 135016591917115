import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

const initialState = {
  getProductListRetrieving: true,
  getProductListError: null,
  getProductListResponse: {},

  getProductDetailsRetrieving: true,
  getProductDetailsError: null,
  getProductDetailsResponse: {},

  getProductVariantInfoRetrieving: false,

  getStoreSettingsRetrieving: false,
  getStoreSettingsError: null,
  getStoreSettingsResponse: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PRODUCT_VARIANT_INFO_FULFILLED: {
      const newState = {
        ...state,
        getProductDetailsResponse: action.body,
        getProductVariantInfoRetrieving: false
      }
      return newState
    }
  }
  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PRODUCT_LIST) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PRODUCT_DETAILS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PRODUCT_VARIANT_INFO) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_STORE_SETTINGS) ||
    state
  )
}

export default reducer
