import AuthenticationContainer from './containers'
import ReducerFuncs from './store'
import AuthenticationHelper from './authentication-helper'

export default AuthenticationHelper

const Reducers = (reducerObj) => { reducerObj.auth = ReducerFuncs }

export {
  AuthenticationContainer,
  Reducers
}
