import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

const Routes = [
  {
    pattern: '/products/detail/:productId',
    component: LazyLoadWithCircularProgress(() => import('./containers/product-details')),
    isPublic: true
  },
  {
    pattern: '/products',
    component: LazyLoadWithCircularProgress(() => import('./containers/base')),
    isPublic: true
  }]

export default Routes
