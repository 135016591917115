import React from 'react'

import config from '../config'

// redux
import { Provider } from 'react-redux'

// helpers
import BaseRouter, { Reducer as RouterReducer } from '@eig-builder/module-router'

// i8n
import { LocalizationContainer, LocalizeDebugHelper } from 'localize'
import LocalizationReducerFuncs from 'localize/store'

// Routes
import { Routes as CheckoutRouter, Reducers as CheckoutReducer } from './../../../modules/checkout'
import { Routes as CartRouter, Reducers as CartReducer } from './../../../modules/cart'
import { Routes as ProductGridRouter, Reducers as ProductGridReducer } from './../../../modules/products-grid'
import { Routes as NotFoundRouter } from './../../../modules/notfound'
import { Routes as DigitalDownloadRouter, Reducers as DigitalDownloadReducer } from './../../../modules/digital-download'

// Analytics
import AnalyticsService from '@eig-builder/core-utils/analytics/analytics-service'
import AppmachineInsightsLogger from '@eig-builder/core-utils/analytics/loggers/appmachine-insights-logger'

// Notification Bar
import { NotificationBarWrapperWithContext } from '@eig-builder/module-notification-bar-with-context'

// Modals
import { Reducers as ModalsReducers } from '@eig-builder/module-modals'

// Authentication
import { AuthenticationContainer, Reducers as AuthenticationReducers } from 'authentication_alias'

import { PropertyEditorContext } from '@eig-builder/module-property-editor'
import Select from '@eig-builder/module-property-editor-field-select'
import Autocomplete from '@eig-builder/module-property-editor-field-autocomplete'

// Wrappers
import Wrapper from './components/app-wrapper'

// Material-core theme
import { MuiThemeProvider, createMuiTheme } from '@eig-builder/core-ui/styles'
import BrandMuiTheme from 'muitheme'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import '@eig-builder/core-utils/base-style/main.scss'

import * as GlobalStore from '@eig-builder/core-utils/store'

const muiTheme = createMuiTheme(BrandMuiTheme)

const analyticsService = new AnalyticsService([AppmachineInsightsLogger])

const LocalizationReducers = reducerObj => {
  reducerObj.localize = LocalizationReducerFuncs
}

const routes = [
  NotFoundRouter,
  CheckoutRouter,
  CartRouter,
  ProductGridRouter,
  DigitalDownloadRouter
]

const store = GlobalStore.create(config, [
  RouterReducer,
  LocalizationReducers,
  ModalsReducers,
  CheckoutReducer,
  CartReducer,
  ProductGridReducer,
  AuthenticationReducers,
  DigitalDownloadReducer
])

store.dispatch({
  type: 'AVAILABLE_ROUTES',
  payload: routes
})

const propertyEditorContextValue = {
  useApiGateway: true,
  additionalFieldTypes: [
    Select,
    Autocomplete
  ]
}

export default () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <StyledThemeProvider theme={muiTheme}>
          <LocalizationContainer _localizeDebug={LocalizeDebugHelper()}>
            <NotificationBarWrapperWithContext>
              <AuthenticationContainer analytics={analyticsService}>
                <PropertyEditorContext.Provider value={propertyEditorContextValue}>
                  <BaseRouter
                    analytics={analyticsService}
                    store={store}
                    routes={routes}
                    appWrapper={Wrapper}
                    defaultRoute='/notfound'
                  />
                </PropertyEditorContext.Provider>
              </AuthenticationContainer>
            </NotificationBarWrapperWithContext>
          </LocalizationContainer>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </Provider>
  )
}
